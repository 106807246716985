import React, { useEffect, useRef, useMemo } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import Lottie from 'lottie-web';
import { traceEvent } from '@wk/wk-gatherer';
import AbsentMathImg from '@public/imgs/pc/absent/absent-math.png';
import AbsentEnglishImg from '@public/imgs/pc/absent/absent-english.png';
import AbsentChineseImg from '@public/imgs/pc/absent/absent-chinese.png';
import defaultAvatarImg from '@public/imgs/icon-default-avatar.svg';
import iconFlower from '@public/imgs/icon-flower.svg';
import NewButton, { TypeButton } from '@/components/NewButton';
import { getClassTypeName } from '@/pageComponents/Home/util';
import 'swiper/css';
import 'swiper/css/pagination';
import { ScheduleDetailRes } from '@/api/types/curriculum';
import CourseInfoForAbsent from '@/pageComponents/Common/CourseInfoForAbsent';
import useModuleName from '@/store/useModule';
import { goWebSite } from '@/utils/goWebSite';
import styles from './style.module.scss';

const Absent = (props: ScheduleDetailRes & { onBeforeClick: () => boolean }) => {
  const { startDateTime, endDateTime, courseSectionRespDTO, teacher, classType, onBeforeClick } = props;
  const { t, i18n } = useTranslation('home');
  const ref = useRef(null);
  const { updateModuleName } = useModuleName();

  useEffect(() => {
    if (ref.current) {
      Lottie.loadAnimation({ container: ref.current, loop: true, path: '/json/absent.json' });
    }
  }, []);

  const { stageDisplay, course, sectionNameDisplay, sectionNameDisplayEn } = courseSectionRespDTO || {};

  const memorizedCurAbsentInfo = useMemo(() => {
    const subjectToAbsentCopyWriting = {
      MATH: {
        courseTitle: t('数学体验课'),
        tags: [t('首页-数学-体验课-tag-提示-1'), t('首页-数学-体验课-tag-提示-2')],
        img: AbsentMathImg,
      },
      CHINESE: {
        courseTitle: t('中文体验课'),
        tags: [t('首页-中文-体验课-tag-提示-1'), t('首页-中文-体验课-tag-提示-2')],
        img: AbsentChineseImg,
      },
      /*
       *   todo: missing english obj ?
       * */
      ENGLISH: {
        courseTitle: t('英文体验课'),
        tags: [t('首页-英文-体验课-tag-提示-1'), t('首页-英文-体验课-tag-提示-2')],
        img: AbsentEnglishImg,
      },
    };
    if (course.subject) {
      return subjectToAbsentCopyWriting[course.subject];
    }
    return null;
  }, [course.subject, t]);

  const handleReserve = () => {
    if (!onBeforeClick()) {
      updateModuleName('课程回顾缺席');
      return;
    }
    const data = {
      _event: 'O_PC_HomeReviewAbsentClick',
      subject: course.subject,
    };
    traceEvent({
      data,
    });
    // router.push(`/home/independent-appointment?subject=${course?.subject?.toLowerCase()}`);
    goWebSite(`independent-appointment?subject=${course?.subject?.toLowerCase()}`);
  };

  return (
    <div className={styles.absent}>
      <div className={styles.titleBox}>{t('课程回顾')}</div>
      <div className={styles.lessonBox}>
        <CourseInfoForAbsent
          locale={i18n.language as 'zh' | 'en'}
          startDateTime={startDateTime}
          endDateTime={endDateTime}
          subTitle={stageDisplay}
          classType={t(getClassTypeName(classType))}
          fullName={teacher.fullName}
          teacherHeadImg={teacher.wkHeadImageUrl}
          courseTitle={
            <span className={styles.courseTitleReview}>
              {i18n.language === 'zh' ? sectionNameDisplay : sectionNameDisplayEn}
            </span>
          }
          enablePoint
          operateRender={() => {
            return <span className={styles.absentStatus}>{t('首页-缺席')}</span>;
          }}
        />
        <div className={styles.bannerBox}>
          <div className={styles.boxLeft}>
            <Image src={defaultAvatarImg} alt="" />
          </div>
          <div className={styles.boxRight}>
            <div className={styles.prompt}>
              <div dangerouslySetInnerHTML={{ __html: t('首页-体验课-错过-提示') }} />
              <span className={styles.img}>
                <Image src={iconFlower} alt="" />
              </span>
            </div>
            <div className={styles.courseInfo}>
              <Image width={80} height={80} src={memorizedCurAbsentInfo?.img || ''} alt={'course img'} />
              <div className={styles.intro}>
                <span className={styles.courseTitle}>{memorizedCurAbsentInfo?.courseTitle}</span>
                <div>
                  {memorizedCurAbsentInfo?.tags.map((tag: string) => (
                    <span key={tag} className={styles.tag}>
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
              <NewButton
                type={TypeButton.RED}
                text={t('首页-约课-立即预约')}
                onClick={handleReserve}
                className={styles.reserve}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Absent;
